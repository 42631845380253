import searchItemsMiddleware from '../../search-items/middleware';
import textSlidersMiddleware from '../../text-sliders/middleware';
import homePageMiddleware from '../../homepage/middleware';
import homepageTopCarouselMiddleware from '../../carousels/homepage-top-carousel/middleware';
import productPageMiddleware from '../../product-overview/middleware';
import userMiddleware from '../../user/middleware';
import initMiddleware from '../../init/middleware';
import bookstoresMiddleware from '../../bookstores/middleware';
import wspayMiddleware from '../../wspay/middleware';
import bookstoreMiddleware from '../../bookstore-overview/middleware';
import profileMiddleware from '../../profile/middleware';
import commentsMiddleware from '../../comments/middleware';
import newsMiddleware from '../../news/middleware';
import newsOverviewMiddleware from '../../news-overview/middleware';
import countriesMiddleware from '../../countries/middleware';
import municipalitiesMiddleware from '../../municipalities/middleware';
import wishListMiddleware from '../../wishlist/middleware';
import userConfirmationMiddleware from '../../confirm-registration/middleware';
import lastViewedMiddleware from '../../last-viewed/middleware';
import cartMiddleware from '../../cart/middleware';
import askAboutProductMiddleware from '../../ask-about-product/middleware';
import kidsBookMiddleware from '../../kids-book/middleware';
import foreignBookMiddleware from '../../foreign-book/middleware';
import schoolBookMiddleware from '../../school-book/middleware';
import actionsMiddleware from '../../actions/middleware';
import checkoutMiddleware from '../../checkout/middleware';
import administrativeBanMiddleware from '../../administrative-ban/middleware';
import bookMakerMagazineMiddleware from '../../bookmaker-magazine/middleware';
import careerMiddleware from '../../delfi-career/middleware';
import advancedSearchPageDataMiddleware from '../../advanced_search/middleware';
import searchParamsMiddleware from '../../searchParams/middleware';
import faqMiddleware from '../../faq/middleware';
import productListPageMiddleware from '../../products-list/middleware';
import sectionPageMiddleware from '../../section/middleware';
import newsLetterMiddleware from '../../newsletter/middleware';
import newsLetterConfirmationMiddleware from '../../confirm-newsletter/middleware';
import sendForgotPasswordMiddleware from '../../forgot-user-password/middleware';
import sendResetPasswordMiddleware from '../../reset-user-password/middleware';
import edenBooksMiddleware from '../../eden_books/middleware';
import orderStatusMiddleware from '../../order-status/middleware';
import availabilityInBookstoresMiddleware from '../../bookstore-availability/middleware';
import starWayPageMiddleware from '../../starway/middleware';
import bookPageMiddleware from '../../book/middleware';
import giftPageMiddleware from '../../gift/middleware';
import staticPageDataMiddleware from '../../static-page/middleware';
import complaintPageMiddleware from '../../complaint/middleware';
import productByNavIdMiddleware from '../../product-by-nav-id/middleware';
import menuItemsMiddleware from '../../menu-items/middleware';
import submitAvailableNotificationDataMiddleware from '../../sent-available-notification/middleware';
import actionsListMiddleware from '../../actions-list/middleware';
import booktokMiddleware from '../../booktok/middleware';
import topListsMiddleware from '../../toplists/middleware';
import musicMiddleware from '../../music/middleware';
import videoGameMiddleware from '../../game/middleware';
import filmMiddleware from '../../film/middleware';
import premiumActionsMiddleware from '../../premium-actions/middleware';
import gtmMiddleware from '../../gtm/middleware';
import redirectMiddleware from '../../redirect-middleware/middleware';
import filtersDataMiddleware from '../../filters-data/middleware';
import newsLetterUnsubscribeMiddleware from '../../newsletter-unsubscribe/middleware';
import notFoundUrlMiddleware from '../../404/middleware';
import cookieConsentMiddleware from '../../cookie-consent/middleware';
import boardGamesPageMiddleware from '../../board-games/middleware';
import russianBooksPageMiddleware from '../../russian-books/middleware';
import mangePageMiddleware from '../../mange/middleware';
import magyarPageMiddleware from '../../magyar/middleware';
import authorPageDataMiddleware from '../../author-page/middleware';
import genrePageDataMiddleware from '../../genre-page/middleware';

export const appProviderMiddleware = [
  initMiddleware,
  cookieConsentMiddleware,
  ...searchItemsMiddleware,
  textSlidersMiddleware,
  ...homePageMiddleware,
  ...productPageMiddleware,
  homepageTopCarouselMiddleware,
  ...userMiddleware,
  ...bookstoresMiddleware,
  ...bookstoreMiddleware,
  ...profileMiddleware,
  ...commentsMiddleware,
  ...newsMiddleware,
  ...newsOverviewMiddleware,
  countriesMiddleware,
  municipalitiesMiddleware,
  ...wishListMiddleware,
  userConfirmationMiddleware,
  ...lastViewedMiddleware,
  ...wspayMiddleware,
  ...filtersDataMiddleware,
  askAboutProductMiddleware,
  ...kidsBookMiddleware,
  ...foreignBookMiddleware,
  ...bookPageMiddleware,
  ...giftPageMiddleware,
  ...schoolBookMiddleware,
  ...actionsMiddleware,
  ...starWayPageMiddleware,
  ...checkoutMiddleware,
  ...administrativeBanMiddleware,
  ...bookMakerMagazineMiddleware,
  ...careerMiddleware,
  ...advancedSearchPageDataMiddleware,
  searchParamsMiddleware,
  ...faqMiddleware,
  ...musicMiddleware,
  ...videoGameMiddleware,
  ...productListPageMiddleware,
  ...newsLetterMiddleware,
  newsLetterConfirmationMiddleware,
  ...sectionPageMiddleware,
  ...sendForgotPasswordMiddleware,
  ...sendResetPasswordMiddleware,
  ...edenBooksMiddleware,
  ...orderStatusMiddleware,
  ...availabilityInBookstoresMiddleware,
  ...staticPageDataMiddleware,
  ...complaintPageMiddleware,
  ...productByNavIdMiddleware,
  ...menuItemsMiddleware,
  submitAvailableNotificationDataMiddleware,
  ...actionsListMiddleware,
  ...booktokMiddleware,
  ...topListsMiddleware,
  ...filmMiddleware,
  ...premiumActionsMiddleware,
  ...redirectMiddleware,
  ...cartMiddleware,
  ...newsLetterUnsubscribeMiddleware,
  ...notFoundUrlMiddleware,
  ...boardGamesPageMiddleware,
  ...russianBooksPageMiddleware,
  ...mangePageMiddleware,
  ...magyarPageMiddleware,
  ...authorPageDataMiddleware,
  ...genrePageDataMiddleware,
  gtmMiddleware
];
